import React, { useEffect } from "react";

import { graphql } from "gatsby";
import { navigate } from "gatsby";

import { 
	ChallengeListing, 
	ErrorUI,
	getChallengeStatus,
	Layout
} from "../components";

const ChallengeTemplate = ({ data: { challenge } }) => {

	const title = challenge?.title;
	const status = getChallengeStatus(challenge);
	
	useEffect(() => {
		if ( status === 'waiting' ) {
			navigate('/');
		}
	}, [status]);

	return status === 'waiting' ? null : (
		<Layout 
			title={status === 'waiting' ? '404 - Page Not Found' : `Challenge - ${title}`}
		>
			{status === 'waiting' ? (
				<ErrorUI 
					content={"Sorry, The page you are looking does not exist :("}
					title={"Not Found"}
				/>
			) : (
				<ChallengeListing 
					challenge={challenge} 
				/>
			)}
		</Layout>
	);
}

export default ChallengeTemplate;

export const pageQuery = graphql`
	query ChallengeById(
		$id: String!
	) {
		challenge: wpChallenge(id: { eq: $id }) {
			id
			databaseId
			title
			questionVideo
			answerVideo
			startDatetime
			endDatetime
			rewardImage {
				altText
				localFile {
					icon: childImageSharp {
						gatsbyImageData(
							height: 32
							placeholder: TRACED_SVG
						)
					}
					small: childImageSharp {
						gatsbyImageData(
							height: 80
							placeholder: TRACED_SVG
						)
					}
					medium: childImageSharp {
						gatsbyImageData(
							placeholder: TRACED_SVG
							width: 200
						)
					}
				}
			}
			rewardName
			rewardValue
			rewardURL
			rewardSpots
			questionFormFields
			betWindows
			bannerChatBottomDestination
			bannerChatBottomImage {
				altText
				localFile {
					small: childImageSharp {
						gatsbyImageData(
							height: 60
							placeholder: TRACED_SVG
						)
					}
					medium: childImageSharp {
						gatsbyImageData(
							placeholder: TRACED_SVG
							width: 400
						)
					}
				}
			}
			bannerChatTopDestination
			bannerChatTopImage {
				altText
				localFile {
					small: childImageSharp {
						gatsbyImageData(
							height: 60
							placeholder: TRACED_SVG
						)
					}
					medium: childImageSharp {
						gatsbyImageData(
							placeholder: TRACED_SVG
							width: 400
						)
					}
				}
			}
			adPreRollId
			adPreRollHeadline
      		adPreRollDisplayUrl
      		adPreRollFinalUrl
			adEndRollId
			adEndRollHeadline
      		adEndRollDisplayUrl
      		adEndRollFinalUrl
			adReplayId
			adReplayHeadline
			adReplayDisplayUrl
			adReplayFinalUrl
		}
	}
`